import React, { useState, useEffect, Fragment } from "react";
import { Row, Col } from "react-flexbox-grid";
import { PatientPage, SEO } from "components";
import GoogleMapReact from "google-map-react";
import axios from "axios";
import imgTick from "images/tick.png";
import imgGooglePin from "images/google-pin.png";
import "./aveed-finder.scss";

const pageDescription = "Locate a specialist near you who is trained to inject AVEED® (testosterone undecanoate).";

//<!-- Marker Component Starts Here. Scroll down for the Page Component. -->
const Marker = ({ locationDetails }) => {
  const [showMarker, setShowMarker] = useState(false);
  const handleShow = () => {
    setShowMarker(true);
  };

  const handleHide = () => {
    setShowMarker(false);
  };

  return (
    <div className="google-marker">
      <img
        src={imgGooglePin}
        className="google-pin"
        alt="Google Map Pin"
        onClick={handleShow}
      />
      {showMarker && (
        <div className="location-detail">
          <div>
            <p className="m-practice-name strong">
              {locationDetails.practice_name}
            </p>
            {locationDetails.street_address1 && (
              <p className="m-street-address1">
                {locationDetails.street_address1}
              </p>
            )}
            {locationDetails.street_address2 && (
              <p className="m-street-address2">
                {locationDetails.street_address2}
              </p>
            )}
            <p className="m-city">
              {locationDetails.city}, {locationDetails.state}{" "}
              {locationDetails.zip}
            </p>
            {locationDetails.tel && (
              <p className="m-tel">{locationDetails.tel}</p>
            )}
          </div>
          <button className="btn-close-info" onClick={handleHide}>
            X
          </button>
        </div>
      )}
    </div>
  );
};
//<!--Ends Marker Component -->

//<!-- Page Component Starts Here -->
let SESSION_ID = "";
const AveedFinderPage = () => {
  const [zipCode, setZipCode] = useState("");
  const [zipRadius, setZipRadius] = useState(25);
  const [hcpData, setHcpData] = useState([]);
  const [fetchinData, setFetchingData] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [nonPriorityList, setnonPriorityList] = useState(false);

  const hcpCounter = () => {
    const isThereThree = hcpData.length;
    if (isThereThree < 3) {
      setnonPriorityList(true);
      setHcpData([]);
    }
    else {
      return isThereThree < 3;
    }
  }

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
    maps.Map(document.getElementById("map"), {
      clickableIcons: false,
    });
  };

  //RETURN false if there are no non priority doctors
  const isAnyHCPNonPriority = (hcps) => {
    const nonPriorityHcps = hcps.filter((hcp) => {
      return hcp.IsPriority === false
    })
    return nonPriorityHcps.length > 0;
  }

  const getSessionId = async () => {
    setFetchingData(true);
    try {
      const response = await axios.get(
        `${process.env.ENDO_HCP_LOCATOR_API_ENDPOINT}/BeginSession?api_key=${process.env.ENDO_HCP_LOCATOR_API_KEY}&website_id=${process.env.ENDO_HCP_LOCATOR_WEBSITE_ID}`
      );
      setFetchingData(false);
      return response.data.SessionID;
    } catch (error) {
      console.error(error);
      setFetchingData(false);
    }
  };

  const getData = async (_session_id) => {
    setFetchingData(true);
    try {
      const response = await axios.get(
        `${process.env.ENDO_HCP_LOCATOR_API_ENDPOINT}/GetHcps/${process.env.BRAND_NAME
        }/${zipCode || "10001"}/${zipRadius}?website_id=${process.env.ENDO_HCP_LOCATOR_WEBSITE_ID
        }&api_key=${process.env.ENDO_HCP_LOCATOR_API_KEY
        }&sort_by_priority=false&session_id=${_session_id}`
      );

      if (response.data.Status.StatusCode !== 0) {
        //No results found
        setNoResultsMessage(response.data.Status.StatusMessage);
      } else if (!isAnyHCPNonPriority(response.data.Hcps)) {
        //reset list to zero we have doctors that are all priority therefore hide results
        setnonPriorityList(true);
        setHcpData([]);
      }
      else {
        setHcpData(response.data.Hcps);
        setNoResultsMessage("");
      }
      setFetchingData(false);

      // return response;
    } catch (error) {
      console.error(error);
      setFetchingData(false);
    }
  };

  const handleRadiusChange = (e) => {
    setZipRadius(e.target.value);
  };

  const handleZipChange = (e) => {
    setZipCode(e.target.value);
  };

  const handleKeyPress = async (e) => {
    if (e.which == 13 || e.keyCode == 13) {
      await getData(SESSION_ID);
    }
  };

  const handleSearchClick = async () => {
    const zipREGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
    if (zipREGEX.test(zipCode)) {
      await getData(SESSION_ID);
    } else {
      alert("Error: Invalid ZIP Code!");
    }
  };

  useEffect(() => {
    (async () => {
      SESSION_ID = await getSessionId();
      await getData(SESSION_ID);
    })();
  }, []);

  return (
    <PatientPage pageName="aveed-finder" pageClassName="patient-aveed-finder">
      <SEO pageTitle="AVEED® Finder" pageDescription={pageDescription} />
      <div className="dfa-container gutter-left gutter-right">
        <h1>AVEEDfinder</h1>
        <Row>
          <Col xs={12}>
            <p>
              Enter your search radius and ZIP Code. Our system will locate a
              physician near you.
            </p>
            <div className="search-bar">
              <div className="search-group search-distance">
                <label>Choose distance:</label>
                <select
                  id="av-finder-distance"
                  onChange={handleRadiusChange}
                  value={zipRadius}
                >
                  <option value="25" defaultValue>
                    25 Miles
                  </option>
                  <option value="50">50 Miles</option>
                  <option value="100">100 Miles</option>
                </select>
              </div>
              <div className="search-group search-zip">
                <label>Enter ZIP Code:</label>
                <input
                  type="text"
                  id="av-finder-zipcode"
                  placeholder=""
                  onChange={handleZipChange}
                  onKeyPress={handleKeyPress}
                  value={zipCode}
                  maxLength={5}
                />
              </div>
              <div className="search-group search-button">
                <button onClick={handleSearchClick}>SEARCH</button>
              </div>
            </div>
          </Col>
        </Row>
        <Row style={{ overflow: "hidden" }}>
          <Col xs={12} style={{ overflow: "hidden" }} className={nonPriorityList && hcpData.length == 0 ? 'hide-map-box' : ''}>
            <div style={{ height: "400px", width: "100%" }}>
              {fetchinData ? (
                <p className="loading-indicator">Loading...</p>
              ) : (
                <Fragment>
                  {noResultsMessage ? (
                    <p className="server-error-message">
                      Invalid ZIP Code. Try again with a valid ZIP Code. <br />
                      If it, still, doesn't work refresh the browser and try
                      again.
                    </p>
                  ) : (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyB1lMALR1EfivG1iqJ_hbThweKQmDDlM_o",
                      }}
                      yesIWantToUseGoogleMapApiInternals
                      defaultCenter={{
                        lat: hcpData[0]?.Practice.Locations[0].Latitude,
                        lng: hcpData[0]?.Practice.Locations[0].Longitude,
                      }}
                      defaultZoom={13}
                    >
                      {hcpData?.map((hcp) => {
                        return hcp.Practice.Locations.map((location, index) => {
                          return (
                            <Marker
                              key={index}
                              lat={location.Latitude}
                              lng={location.Longitude}
                              locationDetails={{
                                practice_name: hcp.Practice.Name || "",
                                street_address1: location.StreetAddress1 || "",
                                street_address2: location.StreetAddress2 || "",
                                city: location.City || "",
                                state: location.State || "",
                                zip: location.ZipCode || "",
                                tel: location.Phone || "",
                              }}
                            />
                          );
                        });
                      })}
                    </GoogleMapReact>
                  )}
                </Fragment>
              )}
            </div>
          </Col>
          <Col xs={12}>
            <div className="hcp-locator-pre-text">
              <p>
                Physicians listed on this website are not affiliated with Endo Pharmaceuticals Inc. and do not pay to be on this list or to be designated as experienced users of AVEED<sup>&reg;</sup>.
              </p>
              <p>
                The AVEEDfinder is a directory of physicians who have notified
                Endo Pharmaceuticals Inc. that they are experienced in the
                injection procedure of AVEED<sup>&reg;</sup>. Inclusion of a physician in
                the AVEEDfinder does not constitute a referral,
                recommendation, endorsement, or verification of credentials,
                qualifications, or abilities of the physician listed.
                Conversely, the absence of a physician's name and information
                should not be construed as a negative comment from Endo
                Pharmaceuticals Inc. about the physician's credentials,
                qualifications, or abilities.
              </p>
              <p>
                Endo Pharmaceuticals Inc. shall not be liable for any harm
                resulting from your reliance on information contained in the
                AVEEDfinder.
              </p>
            </div>
            <div className="finder-results-block">
              <div className="results-header">
                <img className="tick-image" src={imgTick} />
                <p>
                  HCPs who have administered AVEED<sup>&reg;</sup>, 12 or more
                  times over the past 12 months*
                </p>
              </div>
              <div className="results-list-container">
                {!fetchinData && (
                  <div className="results-list">
                    {hcpData.length === 0 && nonPriorityList || hcpCounter() ? (
                      <p className="results-found-text">
                        Sorry! For best results, please try again by expanding your search area to find more Physicians.
                      </p>
                    ) : (
                      <p className="results-found-text">
                        {hcpData.length} RESULTS FOUND!
                      </p>
                    )}
                    {hcpData?.map((hcp, index) => {
                      return (
                        <div className="result-row" key={index}>
                          <div className="tick-box">
                            {hcp.IsPriority && (
                              <img src={imgTick} className="tick-image" />
                            )}
                          </div>
                          <div className="index-box">{index + 1}.</div>
                          <div className="practice-details">
                            <p className="hcp-name strong">
                              {hcp.FirstName} {hcp.MiddleName && hcp.MiddleName}{" "}
                              {hcp.LastName}
                              {hcp.Suffix && `, ${hcp.Suffix}`}
                              {hcp.Specialty && `, ${hcp.Specialty}`}
                            </p>
                            <p className="practice-name">{hcp.Practice.Name}</p>
                            {hcp.Practice.Locations.map((location, index) => {
                              return (
                                <div className="practice-location" key={index}>
                                  <p className="practice-address">
                                    <a
                                      href={`https://maps.google.com/?q=${location.Latitude},${location.Longitude}`}
                                      target="_blank"
                                    >
                                      {location.StreetAddress1 &&
                                        location.StreetAddress1}
                                      {location.StreetAddress2 && (
                                        <Fragment>
                                          <br />
                                          {location.StreetAddress2}
                                        </Fragment>
                                      )}
                                      <br />
                                      {location.City}, {location.State}{" "}
                                      {location.ZipCode}
                                    </a>
                                  </p>
                                  {location.Phone && (
                                    <p className="practice-phone">
                                      <a href={`tel:${location.Phone}`}>
                                        ☎ {location.Phone}
                                      </a>
                                    </p>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="hcp-locator-sub-text">
                <p className="hanging-asterisk">
                  Endo Pharmaceuticals Inc. maintains a list of physicians who
                  have received the necessary Risk Evaluation and Mitigation
                  Strategy (REMS) training and have experience in injecting
                  AVEED<sup>&reg;</sup> 12 or more injections over a 12-month period as designated by the green check mark.
                </p>
                <p>
                  Physicians who have received the necessary training, but have
                  not administered at least 12 injections of AVEED
                  <sup>&reg;</sup> over a 12-month period, are also listed on this
                  website, unless they have administered &lt;1 injection within
                  the last 12 months, in which case they will not be listed. This AVEED<sup>&reg;</sup> usage information will be updated on an annual basis.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </PatientPage>
  );
};

export default AveedFinderPage;
